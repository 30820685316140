/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import '../node_modules/bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}



/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// NobleUI styles For RTL
.dx-datagrid-headers {
  background-color: #2a3042 !important;
  color: #fff !important;
  font-weight: bold !important;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #dc3545;
}